
import { notify, parseDate } from "@/core/helpers/globalJaya.ts";
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Diagnostic } from "@/store/modules/DiagnosticsModule";
import { Actions } from "@/store/enums/StoreEnums";
import FactorLegend from "@/components/diagnostic/FactorLegend.vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "diagnostic-results",
  components: {
    CardTitleJaya,
    FactorLegend,
  },
  setup() {
    interface SeriesObject {
      name: string;
      data: number[];
    }

    const store = useStore();
    const router = useRouter();

    let diagnostics = ref<Diagnostic[]>([]);
    let factorLevels = ref<number[]>([]);
    let globalScore = ref<number>(0);
    let chartType = ref<string>("bar");
    let series = ref<SeriesObject[]>([]);
    let displayChart = ref<boolean>(false);

    const exploitationId = computed(
      () => +router.currentRoute.value.params.exploitationId
    );
    const exploitationName = computed(() => {
      let exploitation = store.getters.getExploitations.find(
        (exploitation) => exploitation.id === exploitationId.value
      );
      if (exploitation && exploitation.name) return exploitation.name;
      return "";
    });
    const factors = computed(() => store.getters.getFactors);

    const chartOptionsBar = computed(() => {
      return {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: [
          getCSSVariableValue("--bs-primary"),
          getCSSVariableValue("--bs-secondary"),
          getCSSVariableValue("--bs-dark"),
          getCSSVariableValue("--bs-warning"),
          getCSSVariableValue("--bs-danger"),
        ],
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return value - 1;
          },
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          lineCap: "butt",
          width: 0.5,
          colors: ["#fff"],
          opacity: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          followCursor: true,
          x: {
            formatter: (label) => {
              let modifiedLabel = factors.value.find(
                (factor) => factor.number === label
              ).title;
              return label + " : " + modifiedLabel;
            },
          },
          y: {
            formatter: (value) => {
              return "Niveau " + (value - 1).toString();
            },
          },
        },
        xaxis: {
          categories: factors.value.map((factor) => factor.number),
          inverseOrder: true,
          crosshairs: {
            show: false,
            width: 1,
          },
        },
        yaxis: {
          position: "top",
          tickAmount: 6,
          min: 0,
          max: 6,
          labels: {
            show: false,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
        },
        fill: {
          opacity: 1,
        },
      };
    });
    const chartOptionsRadar = computed(() => {
      return {
        chart: {
          type: "radar",
        },
        plotOptions: {
          radar: {
            distributed: true,
          },
        },
        colors: [
          getCSSVariableValue("--bs-primary"),
          getCSSVariableValue("--bs-secondary"),
          getCSSVariableValue("--bs-dark"),
          getCSSVariableValue("--bs-warning"),
          getCSSVariableValue("--bs-danger"),
        ],
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return value - 1;
          },
          style: {
            fontSize: "10px",
          },
        },
        stroke: {
          width: 2,
        },
        fill: {
          opacity: 0.1,
        },
        xaxis: {
          categories: factors.value.map((factor) => factor.number),
        },
        yaxis: {
          show: false,
        },
        legend: {
          position: "bottom",
        },
      };
    });

    const setType = (type: string) => {
      chartType.value = type;
      updateSeries();
    };

    const updateSeries = () => {
      series.value = [];
      setTimeout(() => {
        for (const diagnostic of diagnostics.value.sort((a, b) =>
          new Date(a.date_evaluated.toString()).getTime() <
          new Date(b.date_evaluated.toString()).getTime()
            ? -1
            : 1
        )) {
          let results = ref<number[]>([]);
          if (diagnostic.results) {
            for (const factor of factors.value) {
              let resultIndex = diagnostic.results.findIndex(
                (result) => result.level_details.factor === factor.id
              );
              if (diagnostic.results[resultIndex].level_details) {
                results.value.push(
                  diagnostic.results[resultIndex].level_details.level + 1
                );
              }
            }
          }
          series.value.push({
            name: parseDate(diagnostic.date_evaluated),
            data: results.value,
          });
          displayChart.value = true;
        }
      }, 100);
    };

    const displayFactorDetail = (event, chartContext, config) => {
      let clickedDiagnostic = diagnostics.value[config.seriesIndex];
      router.push({
        name: "edit-diagnostic",
        params: { diagnosticId: clickedDiagnostic.id },
        hash: "#" + factors.value[config.dataPointIndex].id.toString(),
      });
    };

    onMounted(() => {
      store
        .dispatch(Actions.FETCH_EXPLOITATIONS)
        .then(() => {
          store.dispatch(Actions.FETCH_QUIZ).then(() => {
            store.dispatch(Actions.FETCH_DIAGNOSTICS).then(() => {
              setCurrentPageTitle("Évolution de l'exploitation");
              diagnostics.value = store.getters.getDiagnostics
                .filter(
                  (diagnostic) =>
                    diagnostic.exploitation_id === exploitationId.value
                )
                .filter((diag) => diag.finished);
              updateSeries();
            });
          });
        })
        .catch(() => {
          router.push({
            name: "homepage",
          });
          notify({
            text: "Diagnostic non trouvé",
            color: "error",
          });
        });
    });

    return {
      diagnostics,
      displayChart,
      factorLevels,
      globalScore,
      chartType,
      chartOptionsBar,
      chartOptionsRadar,
      series,
      exploitationName,
      setType,
      displayFactorDetail,
      factors,
    };
  },
});
